import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  uploadHelper,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

import {
  Container,
  FlexColumn,
  StyledSectionContainer,
  StyledTextField,
  StyledSubtitleContainer,
  StyledDatePicker,
  PreviewBanner,
  StyledBannerContainer,
  SubImageHoverContainer,
  IconButtonHover,
  StyledBoxAdornment,
  PageTitle,
  FlexBetween,
  FlexCenter,
  MandatoryAsterisk,
} from "./styled";

import {
  Dropzone,
  Icons,
  LinkButton,
  Skeleton,
  Toggle,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Grid } from "@mui/material";
import { PageTemplate } from "@components/PageTemplate";
import { StyledText, StyledTitle, dispatchToast, getObjDiffs } from "@utils";

import { useFormik } from "formik";
import * as yup from "yup";

import WarnMessage from "@components/WarnMessage";
import { DraftCourseModal } from "../Modal/DraftCourseModal";
import { trpc } from "@api/client";

export const FirstStep = ({
  data,
  learningObjectData,
  type,
  createCourseMutate,
  updateCourseMutate,
  courseId,
  currentStep,
  isFetching,
}) => {
  const utils = trpc.useContext();
  const navigate = useNavigate();

  const [saveAsDraft, setSaveAsDraft] = useState(false);

  const { selectedCompany } = useSelectedCompany();

  useEffect(() => {
    if (Object.keys(data).length) {
      Object.keys(data).map((cl) => {
        if (
          cl === "name" ||
          cl === "description" ||
          cl === "start" ||
          cl === "end" ||
          cl === "highlight" ||
          cl === "mandatory" ||
          cl === "totalHours" ||
          cl === "banner"
        ) {
          if (cl == "start" && data?.[cl]) {
            formik.setFieldValue("start", dayjs(data?.[cl]));
          }
          if (cl == "end" && data?.[cl]) {
            formik.setFieldValue("end", dayjs(data?.[cl]));
          }
          if (data?.[cl] || cl == "highlight" || cl == "mandatory") {
            formik.setFieldValue(cl, data?.[cl], true);
          }
        }
        return;
      });
    }
  }, [data]);

  const validationSchema = yup.object({
    name: yup
      .string()
      .max(50)
      .required(
        "Favor digitar o nome do treinamento. Este campo é obrigatório."
      ),
    description: yup.string().max(600).notRequired(),
    start: yup
      .date()
      .typeError("Favor selecionar uma data válida")
      .required("Favor selecionar uma data. Este campo é obrigatório."),
    end: yup
      .date()
      .min(
        yup.ref("start"),
        "Data de encerramento não pode ser menor que a Data de início"
      )
      .notRequired()
      .nullable(true)
      .typeError("Favor selecionar uma data válida"),
    highlight: yup.boolean().notRequired(),
    mandatory: yup.boolean().notRequired(),
    totalHours: yup.number().when("saveAsDraft", {
      is: false,
      then: yup
        .number()
        .required("Favor digitar a carga horária. Este campo é obrigatório."),
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      highlight: false,
      start: "",
      end: null,
      banner: { path: "", signature: "" },
      file: "",
      totalHours: "",
      mandatory: false,
      saveAsDraft: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      delete values["saveAsDraft"];

      Object.keys(values).map((v) => {
        const foundValue = values?.[v];
        if (
          !foundValue &&
          v !== "highlight" &&
          v !== "mandatory" &&
          v !== "end"
        )
          delete values[v];
      });

      const file = values?.file;
      let banner = null;

      if (file) {
        const randomNumber = Math.round(Math.random() * 100000000000000);
        const res = await uploadHelper({
          key: `courseBanner/${selectedCompany?.id}`,
          file: file?.file,
          fileName: randomNumber?.toString(),
          module: "learning-management-system",
        });

        banner = { ...res, origin: "internal" };
      }

      delete values["file"];

      if (!courseId) {
        const params = {
          ...values,
          banner,
          companyId: selectedCompany?.id,
          status: "draft",
        };

        createCourseMutate(
          { ...params },
          {
            onSuccess: (data) => {
              const courseId = data?._id;
              utils.course.getManageCourses.invalidate();
              utils.course.getCourseById.invalidate();

              if (saveAsDraft) {
                setSaveAsDraft(true);
                return;
              }

              navigate(
                `/lms/manage-courses/createEditCourse/${type}/${
                  currentStep + 1
                }/${courseId}`
              );
            },
            onError: (e: any) => {
              const courseExists =
                e?.data?.error === "COURSE_WITH_SAME_NAME_ALREADY_EXISTS_ERROR";

              const message = courseExists
                ? "Já existe curso com esse nome."
                : "Erro ao tentar criar o curso, tente novamente mais tarde!";

              dispatchToast({
                type: "error",
                content: message,
              });
            },
          }
        );
      } else {
        delete values["banner"];

        let params = {
          courseId,
          companyId: selectedCompany?.id,
          ...values,
          status: saveAsDraft
            ? "draft"
            : data?.status !== "published"
            ? "draft"
            : "published",
        };

        if (banner) params = { ...params, banner };

        const hasChangedAnyValue = getObjDiffs(params, {
          description: data?.description,
          highlight: data?.highlight,
          mandatory: data?.mandatory,
          name: data?.name,
          start: data?.start,
          end: data?.end || null,
          status: data?.status,
          totalHours: data?.totalHours,
          banner: data?.banner || null,
        });

        if (hasChangedAnyValue)
          updateCourseMutate(
            { ...params },
            {
              onSuccess: () => {
                utils.course.getManageCourses.invalidate();
                utils.course.getCourseById.invalidate();

                if (saveAsDraft) {
                  setSaveAsDraft(true);
                  return;
                }

                navigate(
                  `/lms/manage-courses/createEditCourse/${type}/${
                    currentStep + 1
                  }/${courseId}`
                );
              },
              onError: (e: any) => {
                const courseNotExists =
                  e?.data?.error === "COURSE_NOT_EXISTS_ERROR";

                const message = courseNotExists
                  ? "Não existe o curso."
                  : "Erro ao tentar atualizar o curso, tente novamente mais tarde!";

                dispatchToast({
                  type: "error",
                  content: message,
                });
              },
            }
          );
        else
          navigate(
            `/lms/manage-courses/createEditCourse/${type}/${
              currentStep + 1
            }/${courseId}`
          );
      }
    },
  });

  return (
    <>
      <PageTemplate
        stepper={{
          steps: [
            "Informações básicas",
            "Configurações",
            "Conteúdo do treinamento",
            "Alunos",
            "Revisão",
          ],
          activeStep: currentStep,
        }}
        footer={{
          cancelProps: {
            title: "Sair sem salvar",
            callback: () => {
              navigate("/lms/manage-courses");
            },
          },
          draftProps: {
            title: "Sair e salvar rascunho",
            hasToShow: data?.status != "published",
            callback: () => {
              formik.setFieldValue("saveAsDraft", true);
              setSaveAsDraft(true);

              formik.handleSubmit();
            },
          },
          confirmProps: {
            title: (
              <>
                Continuar
                <Icons name="IconArrowRight" fill="transparent" />
              </>
            ),
            callback: async () => {
              formik.setFieldValue("saveAsDraft", false);
              setSaveAsDraft(false);

              const isValidToSubmit = await formik?.validateForm();

              if (Object.keys(isValidToSubmit)?.length) {
                dispatchToast({
                  content: "Alguns campos estão incorretos. Favor verificar",
                  type: "error",
                });
              }

              formik.handleSubmit();
            },
          },
        }}
      >
        <Container>
          <PageTitle variant="headline6">
            {type === "create" ? "Criar" : "Editar"} treinamento
          </PageTitle>

          {!learningObjectData?.length && !isFetching ? (
            <WarnMessage
              title="Você ainda não criou nenhum conteúdo."
              description="Para criar o treinamento e estruturar as aulas adicionando seus conteúdos é ideal que haja algum conteúdo (vídeo, podcast, PDF, artigo ou avaliação) criado. Por favor, adicione os conteúdos antes de prosseguir com a criação do treinamento."
              redirectAction={() => {
                navigate(`/lms/manage-courses`);
              }}
            />
          ) : null}

          <Grid container>
            {isFetching ? (
              <>
                <Grid item sm={12} md={5} lg={4}>
                  <div style={{ marginRight: "10px" }}>
                    <Skeleton
                      width="100%"
                      height="100vh"
                      animation="pulse"
                      variant="rectangular"
                    />
                  </div>
                </Grid>
                <Grid item sm={12} md={7} lg={8}>
                  <div>
                    <Skeleton
                      width="100%"
                      height="100vh"
                      animation="pulse"
                      variant="rectangular"
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={12} md={5} lg={4}>
                  <FlexColumn style={{ marginRight: "24px" }}>
                    <StyledTitle
                      setColor="secondary50"
                      variant="headline7"
                      style={{ fontWeight: 700, marginBottom: "16px" }}
                    >
                      Informações básicas
                    </StyledTitle>
                    <StyledText
                      setColor="neutral50"
                      variant="body3"
                      style={{ fontWeight: 400 }}
                    >
                      Adicione as informações básicas do seu treinamento, como
                      nome, descrição, período de acesso e personalização.
                    </StyledText>
                  </FlexColumn>
                </Grid>
                <Grid item sm={12} md={7} lg={8}>
                  <StyledSectionContainer style={{ marginBottom: "40px" }}>
                    <FlexBetween>
                      <StyledText variant="headline8">
                        Nome e descrição
                      </StyledText>
                      <div style={{ display: "flex" }}>
                        <MandatoryAsterisk>*</MandatoryAsterisk>
                        <StyledText variant="body3" style={{ fontWeight: 400 }}>
                          campos obrigatórios
                        </StyledText>
                      </div>
                    </FlexBetween>
                    <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                      <StyledText variant="body4">
                        Dê um nome ao treinamento, este será o principal texto
                        exibido nas listas e nos destaques. Na descrição, você
                        pode descrever um resumo do conteúdo e qual o objetivo
                        do treinamento.
                      </StyledText>
                    </div>

                    <StyledTextField
                      id={"name"}
                      name={"name"}
                      label={
                        <>
                          Nome do treinamento{" "}
                          <MandatoryAsterisk>*</MandatoryAsterisk>
                        </>
                      }
                      inputProps={{ maxLength: 50 }}
                      value={formik?.values?.name}
                      onChange={formik?.handleChange}
                      error={
                        formik?.touched?.name && Boolean(formik?.errors?.name)
                      }
                      helperText={
                        formik.touched.name && (formik.errors.name as string)
                      }
                      fullWidth
                    />

                    <StyledSubtitleContainer>
                      <StyledText variant="caption">
                        Máximo 50 caracteres
                      </StyledText>
                      <StyledText variant="caption">
                        {formik?.values?.name?.length}/50
                      </StyledText>
                    </StyledSubtitleContainer>

                    <StyledTextField
                      id={"description"}
                      name={"description"}
                      label={"Descrição do treinamento"}
                      inputProps={{ maxLength: 600 }}
                      value={formik?.values?.description}
                      onChange={formik?.handleChange}
                      error={
                        formik?.touched?.description &&
                        Boolean(formik?.errors?.description)
                      }
                      helperText={
                        formik.touched.description &&
                        (formik.errors.description as string)
                      }
                      multiline
                      rows={4}
                      style={{ marginTop: "24px" }}
                    />
                    <StyledSubtitleContainer>
                      <StyledText variant="caption">
                        Máximo 600 caracteres
                      </StyledText>
                      <StyledText variant="caption">
                        {formik?.values?.description?.length ?? 0}/600
                      </StyledText>
                    </StyledSubtitleContainer>
                  </StyledSectionContainer>

                  <StyledSectionContainer style={{ marginBottom: "40px" }}>
                    <FlexBetween>
                      <StyledText variant="headline8">
                        Período de acesso
                      </StyledText>
                      <div style={{ display: "flex" }}>
                        <MandatoryAsterisk>*</MandatoryAsterisk>
                        <StyledText variant="body3" style={{ fontWeight: 400 }}>
                          campos obrigatórios
                        </StyledText>
                      </div>
                    </FlexBetween>

                    <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                      <StyledText variant="body4">
                        Selecione o período no qual os alunos poderão acessar o
                        treinamento.
                      </StyledText>
                    </div>

                    <StyledDatePicker
                      label={
                        <>
                          Data de início{" "}
                          <MandatoryAsterisk>*</MandatoryAsterisk>
                        </>
                      }
                      id={"start"}
                      name={"start"}
                      fromDate={dayjs()}
                      onDateChange={(value) => {
                        formik?.handleChange({
                          target: {
                            id: "start",
                            value: dayjs(value).toISOString(),
                          },
                        });
                      }}
                      value={
                        formik?.values?.start
                          ? dayjs(formik?.values?.start)
                          : ""
                      }
                      error={
                        formik?.touched?.start && Boolean(formik?.errors?.start)
                      }
                      helperText={
                        formik.touched.start && (formik.errors.start as string)
                      }
                      style={{ width: "100%" }}
                    />

                    <StyledSubtitleContainer style={{ marginBottom: "24px" }}>
                      <StyledText variant="caption">
                        Este campo é obrigatório. Caso a data selecionada seja
                        maior que a data atual, o treinamento será exibido como
                        "Início em dd/mm/aaaa".
                      </StyledText>
                    </StyledSubtitleContainer>

                    <StyledDatePicker
                      label="Data de encerramento"
                      id={"end"}
                      name={"end"}
                      fromDate={dayjs()}
                      onDateChange={(value) => {
                        const date =
                          value && dayjs(value).isValid() ? dayjs(value) : null;

                        const parsedValue =
                          date && dayjs(date).isValid()
                            ? date.toISOString()
                            : null;

                        formik.handleChange({
                          target: {
                            id: "end",
                            value: parsedValue,
                          },
                        });
                      }}
                      value={
                        formik?.values?.end ? dayjs(formik?.values?.end) : ""
                      }
                      error={formik.touched.end && Boolean(formik.errors.end)}
                      helperText={
                        formik.touched.end && (formik.errors.end as string)
                      }
                      style={{ width: "100%" }}
                    />
                    <StyledSubtitleContainer>
                      <StyledText variant="caption">
                        Este campo é opcional. Caso não selecione uma data de
                        encerramento, o treinamento será exibido como "Sempre
                        disponível".
                      </StyledText>
                      <LinkButton
                        variant="primary"
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          document.getElementById("end").value = "";

                          formik.handleChange({
                            target: {
                              id: "end",
                              value: null,
                            },
                          });
                        }}
                        style={{ marginLeft: "8px", alignSelf: "center" }}
                      >
                        <StyledText
                          variant="caption"
                          style={{ fontWeight: 700 }}
                        >
                          Limpar
                        </StyledText>
                      </LinkButton>
                    </StyledSubtitleContainer>
                  </StyledSectionContainer>

                  <StyledSectionContainer style={{ marginBottom: "20px" }}>
                    <FlexBetween>
                      <StyledText variant="headline8">
                        Personalização do treinamento
                      </StyledText>
                      <div style={{ display: "flex" }}>
                        <MandatoryAsterisk>*</MandatoryAsterisk>
                        <StyledText variant="body3" style={{ fontWeight: 400 }}>
                          campos obrigatórios
                        </StyledText>
                      </div>
                    </FlexBetween>

                    <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                      <StyledText variant="body4">
                        Selecione se o treinamento deverá ser feito de forma
                        obrigatória e opte por destacar o treinamento
                        adicionando uma imagem para personalizar.
                      </StyledText>
                    </div>

                    <div style={{ marginBottom: "32px", width: "100%" }}>
                      <StyledTextField
                        id={"totalHours"}
                        name={"totalHours"}
                        label={
                          <>
                            Carga horária (em horas){" "}
                            <MandatoryAsterisk>*</MandatoryAsterisk>
                          </>
                        }
                        inputProps={{ maxLength: 50, min: 0 }}
                        value={formik?.values?.totalHours}
                        onChange={formik?.handleChange}
                        type="number"
                        error={
                          formik?.touched?.totalHours &&
                          Boolean(formik?.errors?.totalHours)
                        }
                        helperText={
                          formik.touched.totalHours &&
                          (formik.errors.totalHours as string)
                        }
                      />

                      <StyledSubtitleContainer>
                        <StyledText variant="caption">
                          A carga horária é um valor estimado que será utilizado
                          para emissão do certificado caso seja habilitada.
                        </StyledText>
                      </StyledSubtitleContainer>
                    </div>

                    <div style={{ marginBottom: "32px", width: "100%" }}>
                      <StyledBoxAdornment
                        title={"Treinamento obrigatório"}
                        description={
                          'Ao selecionar esta opção, o treinamento será exibido como "Obrigatório."'
                        }
                        leftAdornment={
                          <Toggle
                            checked={formik.values.mandatory}
                            onChange={(e) =>
                              formik.handleChange({
                                target: {
                                  id: "mandatory",
                                  value: e.target.checked,
                                },
                              })
                            }
                          />
                        }
                      />
                    </div>

                    <div style={{ marginBottom: "32px", width: "100%" }}>
                      <StyledBoxAdornment
                        title={"Destacar treinamento"}
                        description={
                          "Ao selecionar esta opção, o treinamento ficará destacado nos banners da página de treinamentos do aluno."
                        }
                        leftAdornment={
                          <Toggle
                            checked={formik.values.highlight}
                            onChange={(e) =>
                              formik.handleChange({
                                target: {
                                  id: "highlight",
                                  value: e.target.checked,
                                },
                              })
                            }
                          />
                        }
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      {formik?.values?.banner?.path ? (
                        <PreviewBanner>
                          <StyledText variant="body3">
                            Banner do treinamento
                          </StyledText>
                          <StyledBannerContainer>
                            <img
                              src={`${formik?.values?.banner?.path || ""}${
                                formik?.values?.banner?.signature || ""
                              }`}
                              alt="Imagem do treinamento"
                            />
                            <SubImageHoverContainer>
                              <IconButtonHover
                                size="large"
                                style={{ alignSelf: "center" }}
                                variant="line"
                                onClick={() => {
                                  formik.setFieldValue("banner", null);
                                  formik.setFieldValue("file", "");
                                }}
                              >
                                <Icons name="IconTrash" />
                              </IconButtonHover>
                            </SubImageHoverContainer>
                          </StyledBannerContainer>
                        </PreviewBanner>
                      ) : (
                        <Dropzone
                          title="Tamanho recomendado: 960 × 540 pixels"
                          onChange={(file) => {
                            formik?.handleChange({
                              target: {
                                id: "file",
                                value: file[0],
                              },
                            });
                          }}
                          accept={["png", "jpeg"]}
                        />
                      )}
                    </div>
                  </StyledSectionContainer>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </PageTemplate>

      <DraftCourseModal
        open={saveAsDraft}
        onClose={() => navigate("/lms/manage-courses")}
      />
    </>
  );
};
