import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Dropzone,
  Icons,
  Skeleton,
  TextEditor,
  Toggle,
} from "@flash-tecnologia/hros-web-ui-v2";

import { useFormik } from "formik";
import * as yup from "yup";

import {
  StyledSectionContainer,
  StyledTypography,
  StyledTextField,
  StyledTitle,
  OptionContentArea,
  DropzoneContainer,
  PlayerContainer,
  DisabledContainer,
  Container,
  PageTitle,
  TextEditorContainer,
  PdfContainer,
} from "./styled";

import { track, dispatchToast, StyledText } from "@utils";

import { NoContentModal } from "../Modals/NoContentModal";
import { ConfirmCreateContent } from "../Modals/ConfirmCreateContentModal";
import { LoadingContentModal } from "../Modals/LoadingContentModal";

import Banner from "@components/Banner";
import PdfVisualizer from "@components/PdfVisualizer";
import { Player } from "@components/Player";
import { PageTemplate } from "@components/PageTemplate";

import { Grid } from "@mui/material";

import { uploadHelper } from "@flash-tecnologia/hros-web-utility";

import { ErrorBoundary } from "../../../../utils/ErrorBoundary";

export const ThirdStep = ({
  data,
  learningObject,
  type,
  contentType,
  steps,
  contentId,
  currentStep,
  updateContentMutate,
  isLoadingButton,
  isLoadingScreen,
}) => {
  const navigate = useNavigate();
  const [loadingProgress, setLoadingProgress] = useState<any>(0);

  const [openLoadModal, setOpenLoadModal] = useState<any>(false);
  const [noContentModal, setNoContentModal] = useState<any>(false);
  const [confirmCreateModal, setConfirmCreateModal] = useState<any>(false);

  const [saveAsDraft, setSaveAsDraft] = useState<any>(false);
  const [associateCourses, setAssociateCourses] = useState(0);

  useEffect(() => {
    if (learningObject?.length) {
      const data = learningObject?.filter(({ _id }) => _id === contentId);
      setAssociateCourses(data[0]?.associateCourses ?? 0);
    }
  }, [learningObject]);

  useEffect(() => {
    if (contentType) formik.setFieldValue("contentType", contentType);
  }, [contentType]);

  useEffect(() => {
    if (Object.keys(data).length) {
      Object.keys(data).map((cl) => {
        const value = data?.[cl];
        if (!value) return;

        if (cl == "url") {
          formik.setFieldValue(cl, value);
        }
        if (cl == "content") {
          formik.setFieldValue(cl, value);
        }
      });
    }
  }, [data]);

  const validationSchema = yup.object().shape({
    contentType: yup.string().optional(),
    content: yup.object().when("contentType", {
      is: (val) => val == "article",
      then: () =>
        yup.object().shape({
          html: yup.string().required("Favor digitar o conteúdo"),
          json: yup.string().optional(),
        }),
      otherwise: (schema) => schema.notRequired(),
    }),
    url: yup.object().when("contentType", {
      is: (val) => val != "article",
      then: () =>
        yup.object().shape({
          key: yup.string(),
          path: yup.string(),
          origin: yup.string(),
          signature: yup.string(),
          blobUrl: yup.string().optional(),
          file: yup.mixed(),
        }),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const formik = useFormik<any>({
    initialValues: {
      contentType: "",
      duration: 0,
      url: {
        key: "",
        origin: "",
        path: "",
        signature: "",
        blobUrl: "",
        file: "",
      },
      content: { html: "", json: "" },
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      const file = values?.url?.file || "";
      const path = values?.url?.path || "";
      const isExternal = values?.url?.origin == "external";

      if (values?.contentType != "article") {
        if (isExternal && !path?.match("youtube")) {
          dispatchToast({
            content: "Por favor digite uma url válida",
            type: "error",
          });
          return;
        }
        if (!isExternal && !path && !file && !saveAsDraft) {
          dispatchToast({
            content: "Por favor faça o upload do arquivo novamente",
            type: "error",
          });
          return;
        }
      }

      delete values["contentType"];

      if (values?.url) {
        delete values.url["blobUrl"];
        delete values.url["file"];
      }

      if (values?.url?.signature) {
        navigate("/lms/manage-courses");
        return;
      }

      let params = {
        duration: values?.duration,
        content: values?.content,
        url: values?.url,
        status: saveAsDraft ? "draft" : "published",
      };

      if (!!!params?.content?.html) delete params["content"];

      if (file) {
        setOpenLoadModal(true);

        const res = await uploadHelper({
          key: `${
            contentType === "video" ? "videos" : contentType
          }/${contentId}`,
          file: file.file,
          fileName: `${contentId}${contentType === "video" ? ".mp4" : ""}`,
          module: "learning-management-system",
          onProgress: (value) => setLoadingProgress(value),
        }).catch((e) => {
          setOpenLoadModal(false);

          dispatchToast({
            content:
              "Erro ao fazer o upload do arquivo. Por favor, tente novamente",
            type: "error",
          });

          ErrorBoundary.captureException(
            new Error("Erro ao fazer o upload de um objeto de aprendizado")
          );
          return;
        });

        if (!res && contentType === "video") {
          ErrorBoundary.captureException(
            new Error("Erro não tem response do upload no tipo vídeo")
          );

          if (contentId) {
            updateContentMutate({
              contentId,
              params: { ...params, status: "error" },
            });
          }

          return;
        }

        if (res) {
          params = {
            ...params,
            status:
              contentType === "video" && file ? "processing" : params?.status,
            url: { ...res, origin: "internal" },
          };
        }

        setOpenLoadModal(false);
      }

      if (contentId) {
        updateContentMutate(
          {
            contentId,
            params,
          },
          {
            onSuccess: async () => {
              if (!saveAsDraft) {
                setConfirmCreateModal(true);
              }

              if (saveAsDraft) {
                dispatchToast({
                  content: "Rascunho salvo com sucesso!",
                  type: "success",
                });
                navigate("/lms/manage-courses");
              }
            },
            onError: (e: any) => {
              const learningObjectNotExists =
                e?.data?.error === "LEARNING_OBJECT_NOT_EXISTS_ERROR";

              const message = learningObjectNotExists
                ? "Objeto de aprendizado não existe."
                : "Erro ao tentar atualizar o conteúdo, tente novamente mais tarde!";

              dispatchToast({
                type: "error",
                content: message,
              });
            },
          }
        );
      }
    },
  });
  const isEditing = type === "edit";
  const titleByType =
    contentType === "video"
      ? `${isEditing ? "Editar" : "Criar"} vídeo`
      : contentType === "podcast"
      ? `${isEditing ? "Editar" : "Criar"} podcast`
      : contentType === "pdf"
      ? `${isEditing ? "Editar" : "Criar"} PDF`
      : contentType === "exam"
      ? `${isEditing ? "Editar" : "Criar"} avaliação`
      : `${isEditing ? "Editar" : "Criar"} artigo`;

  const subTitleByType =
    contentType === "video"
      ? "vídeo"
      : contentType === "podcast"
      ? "podcast"
      : contentType === "pdf"
      ? "PDF"
      : contentType === "exam"
      ? "avaliação"
      : "artigo";

  const textByType =
    contentType === "article"
      ? "Escreva seu artigo através do editor de texto."
      : contentType === "pdf"
      ? "Faça o upload do seu conteúdo."
      : "Faça o upload do seu conteúdo ou insira uma URL.";

  const renderPlayer = useMemo(() => {
    const path =
      formik?.values?.url?.path || formik?.values?.url?.blobUrl || "";
    const signature = formik?.values?.url?.signature || "";

    return (
      <PlayerContainer>
        <Player
          key={path}
          onCanPlay={({ duration }) => {
            formik.setFieldValue("duration", duration);
          }}
          mediaToPlay={{
            uri:
              contentType === "podcast" && signature ? path + signature : path,
            signature: signature || null,
          }}
        />
      </PlayerContainer>
    );
  }, [formik?.values?.url]);

  const renderComponent = {
    default: () => {
      const url = formik?.values?.url;
      const path = formik?.values?.url?.path || "";
      const signature = formik?.values?.url?.signature || "";

      const isExternal = formik?.values?.url?.origin == "external";
      const translatedType =
        contentType == "video"
          ? "vídeo"
          : contentType == "podcast"
          ? "podcast"
          : "PDF";

      return (
        <DisabledContainer>
          {contentType !== "pdf" && (
            <OptionContentArea disabled={associateCourses > 0}>
              <div>
                <Toggle
                  checked={isExternal}
                  disabled={associateCourses > 0}
                  onChange={({ target: { checked } }) => {
                    formik.handleChange({
                      target: {
                        id: "url",
                        value: {
                          path: "",
                          origin: checked ? "external" : "internal",
                          signature: "",
                          key: "",
                          blobUrl: "",
                        },
                      },
                    });

                    track({
                      name: `company_lms_${
                        contentType === "exam" ? "test" : contentType
                      }content_addlink_clicked`,
                    });
                  }}
                />
              </div>
              <div>
                <StyledText variant="body3">
                  Inserir URL de vídeo do Youtube
                </StyledText>
                <StyledText variant="body4">
                  Selecione esta opção para inserir a URL do seu vídeo.
                </StyledText>
              </div>
            </OptionContentArea>
          )}

          {isExternal ? (
            <>
              <StyledTextField
                id={"url"}
                name={"url"}
                label={`URL do ${subTitleByType}`}
                value={path}
                disabled={associateCourses > 0}
                onChange={async ({ target: { value } }) => {
                  const params = { ...url, path: value, origin: "external" };

                  formik.handleChange({
                    target: { id: "url", value: params },
                  });
                }}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
              />
              {renderPlayer}
            </>
          ) : path ? (
            contentType == "pdf" ? (
              <PdfContainer id="pdfContainer">
                <PdfVisualizer path={`${path}${signature ?? null}`} />
              </PdfContainer>
            ) : (
              renderPlayer
            )
          ) : (
            <DropzoneContainer>
              <Dropzone
                title={`Upload do ${translatedType}`}
                onChange={(item) => {
                  if (item?.[0]) {
                    const file = item[0];

                    const blobURL = URL.createObjectURL(item?.[0]?.file);
                    const params = { ...url, blobUrl: blobURL, file };

                    formik.handleChange({
                      target: { id: "url", value: params },
                    });
                    return;
                  }

                  const params = { ...url, blobUrl: "", file: "" };

                  formik.handleChange({
                    target: { id: "url", value: params },
                  });
                }}
                style={{ width: "100%" }}
                accept={
                  contentType === "video"
                    ? ["mp4"]
                    : contentType === "podcast"
                    ? ["mp3"]
                    : ["pdf"]
                }
                multiple={false}
              />
            </DropzoneContainer>
          )}
        </DisabledContainer>
      );
    },
    article: () => {
      const html = formik?.values?.content?.html || "<p><br></p>";

      return (
        <TextEditorContainer disabled={associateCourses > 0}>
          <TextEditor
            value={html}
            onChange={(value) => {
              formik.handleChange({
                target: {
                  id: "content",
                  value: {
                    html: value === "<p><br></p>" ? "" : value,
                    json: "",
                  },
                },
              });
            }}
            placeholder="Comece a criar seu artigo aqui..."
            styles={{
              editor: {
                height: 300,
              },
            }}
          />
        </TextEditorContainer>
      );
    },
  };

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: currentStep,
      }}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => {
            track({
              name: `company_lms_${
                contentType === "exam" ? "test" : contentType
              }content_exit_clicked`,
            });
            navigate("/lms/manage-courses");
          },
        },
        draftProps: {
          title: "Sair e salvar rascunho",
          hasToShow: data?.status != "published",
          disabled: !!isLoadingButton,
          callback: () => {
            track({
              name: `company_lms_${
                contentType === "exam" ? "test" : contentType
              }content_savedraft_clicked`,
            });

            setSaveAsDraft(true);
            formik.handleSubmit();
          },
        },
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          disabled: !!isLoadingButton,
          callback: () => {
            navigate(
              `/lms/manage-courses/${type}/content/${contentType}/${
                currentStep - 1
              }/${contentId}`
            );
          },
        },
        confirmProps: {
          title: (
            <>
              Salvar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: !!isLoadingButton,
          callback: async () => {
            track({
              name: `company_lms_${
                contentType === "exam" ? "test" : contentType
              }content_save${
                contentType === "exam" ? "test" : contentType
              }_clicked`,
            });

            const isValidToSubmit = await formik?.validateForm();

            if (Object.keys(isValidToSubmit)?.length) {
              dispatchToast({
                content:
                  "Alguns campos estão incorretos ou não preenchidos. Favor verificar",
                type: "error",
              });
            }

            setSaveAsDraft(false);
            formik.handleSubmit();
          },
        },
      }}
    >
      <Container>
        {isLoadingScreen ? (
          <Skeleton
            width="100%"
            height="80vh"
            animation="pulse"
            variant="rectangular"
            style={{ marginTop: "40px", marginBottom: "40px" }}
          />
        ) : (
          <>
            <PageTitle variant="headline6">{titleByType}</PageTitle>

            {associateCourses > 0 ? (
              <Banner
                type="info"
                icon="IconAlertTriangle"
                title="Edição de conteúdo limitada"
                subTitle="Esse conteúdo está associado a treinamentos e sua edição poderia impactar o progresso dos alunos, portanto, é permitido editar somente as informações básicas. Se a alteração for fundamental, crie um novo conteúdo e associe à aula, mas lembre-se de informar os alunos."
                hasHideBanner={true}
                style={{ marginBottom: "32px" }}
              />
            ) : (
              <></>
            )}

            <Grid container>
              <Grid item sm={12} md={5} lg={4}>
                <div style={{ marginRight: "24px" }}>
                  <StyledTitle variant="headline7">
                    Detalhes do conteúdo
                  </StyledTitle>
                  <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <StyledTypography variant="body3">
                      Adicione e incorpore{" "}
                      {`${
                        subTitleByType === "avaliação"
                          ? "avaliações"
                          : subTitleByType + "s"
                      }`}{" "}
                      para os seus treinamentos, deixando as aulas mais
                      interativas e dinâmicas.
                    </StyledTypography>
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={7} lg={8}>
                <form onSubmit={formik.handleSubmit}>
                  <StyledSectionContainer style={{ marginBottom: "20px" }}>
                    <StyledTypography variant="headline8">
                      Conteúdo do {subTitleByType}
                    </StyledTypography>
                    <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                      <StyledTypography variant="body4">
                        {textByType}
                      </StyledTypography>
                    </div>

                    {renderComponent[
                      contentType !== "article" ? "default" : "article"
                    ]()}
                  </StyledSectionContainer>
                </form>
              </Grid>
            </Grid>
          </>
        )}
      </Container>

      <LoadingContentModal
        open={openLoadModal}
        loadingProgress={loadingProgress}
      />

      <NoContentModal
        open={noContentModal}
        onClose={() => setNoContentModal(false)}
        onConfirm={() => {
          dispatchToast({
            content: "Rascunho salvo com sucesso!",
            type: "success",
          });
          navigate("/lms/manage-courses");
        }}
      />

      <ConfirmCreateContent
        open={!!confirmCreateModal}
        customMessage={
          subTitleByType === "vídeo"
            ? `Seu video está em processamento, e logo estará disponível!`
            : `Seu ${subTitleByType} está disponível para você incorporá-lo nas aulas dos seus treinamentos!`
        }
        onClose={() => {
          setConfirmCreateModal(false);
        }}
      />
    </PageTemplate>
  );
};
